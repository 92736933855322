




































































import Vue from 'vue'

import { mapGetters } from 'vuex'
import { APP_META } from '@/consts'
import { Pagination } from 'vuelpers'
import { checkIsInCart } from '@/mixins/UseCart'
import { Accessors } from 'vue/types/options'
import { Category, Config, Product, Query, SortOrder } from '@/types'
import { baseProductSelectWith, baseCategorySelectWith } from '@/queries'

import ProductsGroup from '@/components/ProductsGroup.vue'
import HomeCategories from '@/components/HomeCategories.vue'

export default Vue.extend({
	name: 'Home',
	metaInfo() {
		return {
			...APP_META,
		}
	},
	components: {
		ProductsGroup,
		HomeCategories,
	},
	created() {
		// FETCH CATAGORIES
		if (!this.$categories.isLoaded && !this.$categories.isLoading) {
			this.$store.dispatch('category/getCategories', {
				page: 1,
				// perPage: 12,
				perPage: 100,
				orderByColumn: 'iListOrder',
				orderByValue: SortOrder.ASC,
				with: baseCategorySelectWith,
			} as Query)
		}
	},
	computed: {
		...({
			...mapGetters(['$config']),
			...mapGetters('category', ['$categories']),
			...mapGetters('products', [
				'$products',
				'$topProducts',
				'$recentProducts',
				'$newArrivedProducts',
				'$sortedRecentProductsIds',
			]),
		} as Accessors<{
			$config: Config
			$categories: Pagination<Category>
			$products: Pagination<Product>
			$topProducts: Pagination<Product>
			$recentProducts: Pagination<Product>
			$newArrivedProducts: Pagination<Product>
			$sortedRecentProductsIds: any[]
		}>),
		categories(): Pagination<Category> {
			return this.$categories
			// return {
			// 	...this.$categories,
			// 	data: this.$categories.data.slice(0, 12),
			// }
		},
	},
	methods: {
		checkIsInCart,
		onIntoViewProducts() {
			if (this.$products.isRefetching || this.$products.isLoaded) return
			// FEAUTURE PRODUCTS
			this.$store.dispatch('products/getProducts', {
				page: 1,
				perPage: 20,
				with: baseProductSelectWith,
			} as Query)
		},
		onIntoViewTopProducts() {
			if (this.$topProducts.isRefetching || this.$topProducts.isLoaded)
				return
			// FETCH TOP PRODUCTS
			this.$store.dispatch('products/getTopProducts', {
				page: 1,
				perPage: 4,
				with: baseProductSelectWith,
			} as Query)
		},
		onRevealRecentProducts() {
			if (this.$recentProducts.isLoaded || this.$recentProducts.isRefetching)
				return
			this.$store.dispatch('products/getRecentProducts', {
				page: 1,
				perPage: 4,
				pointer: 'recent',
				with: baseProductSelectWith,
				queryArray: this.$sortedRecentProductsIds
					.slice(0, 4)
					.map((v: any) => v.id),
			} as Query)
		},
		onRevealNewArrivedProducts() {
			if (
				this.$newArrivedProducts.isLoaded ||
				this.$newArrivedProducts.isRefetching
			)
				return
			// FETCH TOP PRODUCTS
			this.$store.dispatch('products/getNewArrivedProductsProducts', {
				page: 1,
				perPage: 4,
				with: baseProductSelectWith,
			} as Query)
		},
	},
})
