

























import Vue from 'vue'
import { Category } from '@/types'
import { slugify } from '@/helpers'
import { encodeString } from 'vuelpers'
export default Vue.extend({
	name: 'HomeCategoryCard',
	props: {
		category: {
			type: Object as Vue.PropType<Category>,
			required: true,
		},
	},
	methods: {
		slugify,
		encodeString,
		onClickCategory(category: Category) {
			this.$router.push(
				`/categories/${this.slugify(
					category.vCategory
				)}/${this.encodeString(category.iCategoryId)}`
			)
		},
	},
})
