





































































import Vue from 'vue'
import { Category } from '@/types'
import { slugify } from '@/helpers'
import { Pagination, encodeString } from 'vuelpers'

// COMPONENTS
import HomeCategoryCard from './HomeCategoryCard.vue'

// COMPONENT
export default Vue.extend({
	name: 'HomeCategories',
	components: {
		HomeCategoryCard,
	},
	props: {
		categories: {
			required: true,
			type: Object as Vue.PropType<Pagination<Category>>,
		},
	},
	computed: {
		skeleton(): {
			count: number
			height: number
		} {
			const { xl, mdAndUp, smAndUp } = this.$vuetify.breakpoint
			const count = xl ? 12 : mdAndUp ? 6 : smAndUp ? 4 : 2
			const height = xl ? 209 : mdAndUp ? 196 : smAndUp ? 244 : 300
			return { count, height }
		},
	},
	methods: {
		slugify,
		encodeString,
		onClickCategory(category: Category) {
			this.$router.push(
				`/categories/${this.slugify(
					category.vCategory
				)}/${this.encodeString(category.iCategoryId)}`
			)
		},
	},
})
